import { deepmerge } from "@mui/utils"
import type { Color } from "@mui/material"
import type { Theme } from "@mui/material/styles"
import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles"

import { DEFAULT_THEME } from "../../helpers/utils/constants"

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    default: string
    paper: string
    light: string
  }

  interface TypeGlow {
    main: string
  }

  export interface Palette {
    //password
    veryWeak: SimplePaletteColorOptions
    weak: SimplePaletteColorOptions
    fair: SimplePaletteColorOptions
    good: SimplePaletteColorOptions
    strong: SimplePaletteColorOptions
    //gauge
    gaugeBG: SimplePaletteColorOptions
    //conditional greys
    neutral: Partial<Color>
    //pie
    glow: TypeGlow
    // extras
    extras: {
      blue: SimplePaletteColorOptions
      orange: SimplePaletteColorOptions
      purple: SimplePaletteColorOptions
    }
    //angles
    alpha?: SimplePaletteColorOptions
    beta?: SimplePaletteColorOptions
  }

  export interface PaletteOptions {
    //password
    veryWeak?: SimplePaletteColorOptions
    weak?: SimplePaletteColorOptions
    fair?: SimplePaletteColorOptions
    good?: SimplePaletteColorOptions
    strong?: SimplePaletteColorOptions
    //gauge
    gaugeBG: SimplePaletteColorOptions
    //conditional greys
    neutral?: Partial<Color>
    // extras
    extras: {
      blue: SimplePaletteColorOptions
      orange: SimplePaletteColorOptions
      purple: SimplePaletteColorOptions
    }
    //angles
    alpha?: SimplePaletteColorOptions
    beta?: SimplePaletteColorOptions
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    code: object
  }
  interface TypographyVariantsOptions {
    code: object
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    code: true
  }
}

const { palette } = createTheme()

const baseTheme = createTheme({
  palette: {
    grey: {
      100: "#F3F4F6",
      200: "#E5E7EB",
      300: "#D1D5DB",
      400: "#9CA3AF",
      500: "#6B7280",
      600: "#4B5563",
      700: "#272E3A",
      800: "#191F28",
      900: "#11151C",
    },
    action: {
      active: "#6B7280",
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)",
    },
    primary: {
      main: "#009fe3",
      dark: "#22303F",
      contrastText: "#fff",
    },
    secondary: {
      main: "#42B38E",
    },
    warning: {
      main: "#F9B233",
      dark: "#28251E",
      light: "#FFF3DB",
    },
    error: {
      main: "#d32f2f",
      dark: "#281B21",
      light: "#FFE2E2",
    },
    success: {
      main: "#42B38E",
      dark: "#182D2D",
      light: "#E8F5F1",
    },
    info: {
      main: "#009fe3",
      dark: "#112C39",
      light: "#DFF7FF",
    },
    extras: {
      blue: {
        main: "#2187FF",
        light: alpha("#2187FF", 0.15),
      },
      orange: {
        main: "#FF7512",
        light: alpha("#FF7512", 0.15),
      },
      purple: {
        main: "#9B37E9",
        light: alpha("#9B37E9", 0.15),
      },
    },

    /* ---- Custom colors ---- */
    // password level
    veryWeak: palette.augmentColor({ color: { main: "#FF1744" }, name: "veryWeak" }),
    weak: palette.augmentColor({ color: { main: "#FF1744" }, name: "weak" }),
    fair: palette.augmentColor({ color: { main: "#FFC400" }, name: "fair" }),
    good: palette.augmentColor({ color: { main: "#64DD17" }, name: "good" }),
    strong: palette.augmentColor({ color: { main: "#00C853" }, name: "strong" }),

    // gauge level
    gaugeBG: palette.augmentColor({
      color: { main: "#7a7a7a24" },
      name: "gaugeBG",
    }),

    // angles
    alpha: palette.augmentColor({
      color: { main: "#009fe3" },
      name: "alpha",
    }),
    beta: palette.augmentColor({
      color: { main: "#42B38E" },
      name: "beta",
    }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "20px",
            boxShadow: "inset 0 0 10px rgba(0,0,0,0.5)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
          },
          "&::-webkit-scrollbar-corner": {
            background: "inherit",
          },
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeSmall: {
          padding: "6px 16px",
        },
        sizeMedium: {
          padding: "8px 20px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiCheckbox: {
      defaultProps: {
        color: "primary",
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 12,
        },
      },
    },

    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          marginBottom: "1rem",
        },
      },
    },
  },
})

baseTheme.typography = {
  ...baseTheme.typography,
  ..._createTypography(),
}

const darkTheme = createTheme(
  deepmerge(baseTheme, {
    palette: {
      mode: "dark",
      background: {
        default: baseTheme.palette.grey[900],
        paper: baseTheme.palette.grey[800],
        light: baseTheme.palette.grey[700],
      },
      error: {
        main: "#f03e3e",
      },
      text: {
        primary: baseTheme.palette.grey[100],
        secondary: baseTheme.palette.grey[300],
        disabled: baseTheme.palette.grey[500],
      },
      action: {
        active: baseTheme.palette.grey[100],
        disabled: baseTheme.palette.grey[500],
      },
      neutral: {
        100: baseTheme.palette.grey[900],
        200: baseTheme.palette.grey[800],
        300: baseTheme.palette.grey[700],
        400: baseTheme.palette.grey[600],
        500: baseTheme.palette.grey[500],
        600: baseTheme.palette.grey[400],
        700: baseTheme.palette.grey[300],
        800: baseTheme.palette.grey[200],
        900: baseTheme.palette.grey[100],
      },
      glow: {
        main: alpha(baseTheme.palette.grey[500], 0.75),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "*": {
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: baseTheme.palette.grey[900],
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: baseTheme.palette.grey[700],
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: baseTheme.palette.grey[700],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: baseTheme.palette.primary.main,
            borderColor: baseTheme.palette.primary.main,
            "&:hover": {
              backgroundColor: alpha(baseTheme.palette.primary.main, 0.6),
            },
          },
        },
      },
    },
  }),
)

const lightTheme = createTheme(
  deepmerge(baseTheme, {
    palette: {
      mode: "light",
      text: {
        primary: baseTheme.palette.grey[800],
        secondary: baseTheme.palette.grey[700],
        disabled: baseTheme.palette.grey[500],
      },
      background: {
        default: baseTheme.palette.grey[100],
        paper: "#ffffff",
        light: "#ffffff",
      },
      neutral: {
        100: baseTheme.palette.grey[100],
        200: baseTheme.palette.grey[200],
        300: baseTheme.palette.grey[300],
        400: baseTheme.palette.grey[400],
        500: baseTheme.palette.grey[500],
        600: baseTheme.palette.grey[600],
        700: baseTheme.palette.grey[700],
        800: baseTheme.palette.grey[800],
        900: baseTheme.palette.grey[900],
      },
      glow: {
        main: "transparent",
      },
    },
  }),
)

interface IThemes {
  dark: Theme
  light: Theme
}

const themes: IThemes = {
  dark: responsiveFontSizes(darkTheme),
  light: responsiveFontSizes(lightTheme),
}

export const getTheme = (key: string) => {
  return themes[key as keyof IThemes]
    ? themes[key as keyof IThemes]
    : themes[DEFAULT_THEME]
}

export { darkTheme, lightTheme }

export const extraColors = [
  "#12CB94",
  "#2187FF",
  "#53DDF0",
  "#565CED",
  "#57C068",
  "#897FFF",
  "#8FC3FF",
  "#93F446",
  "#9B37E9",
  "#A2A6FF",
  "#AADA22",
  "#AB7DD9",
  "#D2FC2D",
  "#D635CF",
  "#DBBE23",
  "#E05CEC",
  "#E499FF",
  "#ED56B0",
  "#F48F46",
  "#F4AF46",
  "#F4D946",
  "#F818AC",
  "#FF3D83",
  "#FF7512",
]

function _createTypography() {
  const fontSize = 13 // px
  const htmlFontSize = 16
  const coef = fontSize / 14
  const fontWeightLight = 300
  const fontWeightRegular = 400
  const fontWeightMedium = 500
  const fontWeightBold = 600
  const fontFamily = '"Inter", "Helvetica", "Arial", sans-serif'
  const fontFamilyMonospace = "'Roboto Mono', monospace"

  const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`

  const buildVariant = (
    fontWeight: number,
    size: number,
    lineHeight: number,
    casing?: object,
  ) => {
    return {
      fontFamily,
      fontWeight,
      fontSize: pxToRem(size),
      lineHeight: lineHeight / size,
      ...casing,
    }
  }
  const variants = {
    h1: buildVariant(fontWeightMedium, 22, 28),
    h2: buildVariant(fontWeightMedium, 20, 24),
    h3: buildVariant(fontWeightBold, 18, 22),
    h4: buildVariant(fontWeightBold, 16, 22),
    h5: buildVariant(fontWeightBold, 14, 20),
    h6: buildVariant(fontWeightMedium, 14, 18),
    body1: buildVariant(fontWeightRegular, fontSize, 18),
    body2: buildVariant(fontWeightRegular, 12, 18),
    overline: buildVariant(fontWeightMedium, 12, 22, { textTransform: "uppercase" }),
    caption: buildVariant(fontWeightRegular, 12, 16),
    button: buildVariant(fontWeightMedium, 14, 18),
    code: {
      ...buildVariant(fontWeightRegular, 14, 16),
      fontFamily: fontFamilyMonospace,
    },
  }

  return {
    htmlFontSize,
    fontFamily,
    fontFamilyMonospace,
    fontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    ...variants,
  }
}
